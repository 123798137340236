import { AuthContext } from "@vimana/ui-components/dist/Components/Auth/AuthContext";
import { Spinner } from "@vimana/ui-components/dist/Components/Core/Spinner";
import React, { ReactNode, useEffect, useState } from "react";
import { Session } from "vi-auth-session";

import { STORAGE_JWT_ISSUER } from "../constants";
import { autoLogin } from "../Dev/autoLogin";
import { isLocalEnvironment } from "../Dev/isDevelopment";
import { getHost } from "../Utils/fetchAPI";

const getSession = (setLogoutFn, setToken, setLoading) => {
  new Session({
    issuer: STORAGE_JWT_ISSUER,
    logoutURL: `${getHost()}/api/v1/auth/logout`,
    refreshTokenURL: `${getHost()}/api/v1/auth/oauth/token`,
    refreshInterval: 7 * 24 * 60 * 60 * 1000,
    onStart: () => {},
    onLoginRequired: async function login() {
      if (isLocalEnvironment()) {
        await autoLogin(this.fetchTokenForDev.bind(this));
        window.location.reload();
      } else {
        document.location.assign(`${getHost()}/api/v1/auth/login`);
      }
    },
    onToken: function onToken(accessToken, refreshToken) {
      const logoutFn = function () {
        return function () {
          this.logout();
          setTimeout(() => window.location.reload(), 2000);
        }.bind(this);
      }.bind(this);
      setToken({ accessToken, refreshToken });
      setLogoutFn(logoutFn);
      setLoading(false);
    },
    onError: function onError(error) {
      console.error("Received authentication error", error);
      this.logout();
      setTimeout(() => window.location.reload(), 2000);
    },
    onLogout: () => {},
  });
};

type AuthControllerProps = {
  children: ReactNode;
};
export const AuthController = ({ children }: AuthControllerProps) => {
  const [loading, setLoading] = useState(true);
  const [logoutFn, setLogoutFn] = useState(null);
  const [token, setToken] = useState<any>({});
  useEffect(() => getSession(setLogoutFn, setToken, setLoading), []);
  if (loading) return <Spinner />;
  return (
    <AuthContext.Provider token={token.accessToken} refreshToken={token.refreshToken} logout={logoutFn}>
      {children}
    </AuthContext.Provider>
  );
};
