//@ts-nocheck
import React, { createContext } from "react";

export const createEnhancedContext = ({ component }) => {
  const Context = createContext({});
  const LocalProvider = Context.Provider;

  const Provider = ({ children }) => {
    const Component = component;
    return (
      <Component>
        {(props) => {
          return <LocalProvider value={props}>{children}</LocalProvider>;
        }}
      </Component>
    );
  };

  Context.Provider = Provider;
  return Context;
};
