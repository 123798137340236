import React from "react";
import ReactDOM from "react-dom";

import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_NODE_ENVIRONMENT === "test") {
  // SMELL: No other way to move this to ui-integration-tests
  require("whatwg-fetch");
}

const element = document.getElementById("root");

if (element !== null) {
  ReactDOM.render(<App />, element);
}

serviceWorker.register({});
