import { TranslatorContext } from "@vimana/ui-components/dist/Components/Core/Translator";
import React, { ReactNode } from "react";
import { useIntl } from "react-intl";
type Props = {
  /**
   * A render-prop which returns a `React Node`.
   */
  children: ReactNode;
};

/**
 * Translates messages and provides access to React Intl via a render prop.
 */
export const TranslatorSupplier = (props: Props) => {
  const intl = useIntl();

  const translate = (id: string, defaultMessage: string, values: any = {}) => {
    const descriptor = {
      id,
      defaultMessage,
    };
    return intl.formatMessage(descriptor, values);
  };

  const consumerProps = {
    intl,
    translate,
  };
  return <TranslatorContext.Provider value={consumerProps}>{props.children}</TranslatorContext.Provider>;
};
