export const awaitWrapper = (promise: Promise<any>, onError: (...args: Array<any>) => any) => {
  return promise
    .then((response) => {
      return response;
    })
    .catch((error) => {
      onError(error);
      return null;
    });
};
