import { EnvContext as UIEnvContext } from "@vimana/ui-components/dist/Components/Env/EnvContext";
import React, { useContext } from "react";

import { APMController } from "./APMController";
import { ApplicationPropertiesContext } from "./ApplicationPropertiesController";

export const EnvContext = ({ children }) => {
  const applicationProperties: any = useContext(ApplicationPropertiesContext);
  return (
    <APMController applicationProperties={applicationProperties}>
      {(apmProps) => {
        return (
          <UIEnvContext.Provider
            value={{
              ...applicationProperties,
              apmProps,
            }}
          >
            {children}
          </UIEnvContext.Provider>
        );
      }}
    </APMController>
  );
};
