import React, { createContext, ReactNode, useState } from "react";
import { IntlProvider } from "react-intl";

import { getLocaleMessages } from "./localeMessages";
// TODO: Check why we are not able to import the TranslatorSupplier directly from lib-ui
import { TranslatorSupplier } from "./TranslatorSupplier";
const VI_LOCALE_KEY = "vi-locale-key";
type UseLocaleProps =
  | {
      locale: string;
      changeLocale: (arg0: string) => void;
    }
  | Record<string, any>;
export const LocaleContext = createContext<UseLocaleProps>({});

const useLocaleProps = (): UseLocaleProps => {
  if (!localStorage.getItem(VI_LOCALE_KEY)) {
    localStorage.setItem(VI_LOCALE_KEY, "en-us");
  }

  const [locale, setLocale] = useState(localStorage.getItem(VI_LOCALE_KEY) || "");

  const changeLocale = (selectedLocale: string) => {
    localStorage.setItem(VI_LOCALE_KEY, selectedLocale);
    setLocale(selectedLocale);
  };

  return {
    locale,
    changeLocale,
  };
};

type LocaleProviderProps = {
  children: ReactNode;
};
export const LocaleProvider = ({ children }: LocaleProviderProps) => {
  const { locale, changeLocale } = useLocaleProps();
  const messages = getLocaleMessages(locale);
  return (
    <IntlProvider locale={locale} messages={messages}>
      <TranslatorSupplier>
        <LocaleContext.Provider
          value={{
            locale,
            changeLocale,
          }}
        >
          {children}
        </LocaleContext.Provider>
      </TranslatorSupplier>
    </IntlProvider>
  );
};
