import React from "react";
import { Route, Switch } from "react-router-dom";

import usePageView from "../Utils/usePageView";
import { ForgotPasswordRoute } from "./UnProtectedRoutes/ForgotPasswordRoute";
export const UnProtectedRoutes = () => {
  usePageView();
  return (
    <Switch>
      <Route path="/forgot-password" render={() => <ForgotPasswordRoute />} />
    </Switch>
  );
};
