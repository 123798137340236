import React, { createContext, ReactNode } from "react";

import { STORAGE_CURRENT_PLANT_KEY } from "./constants";
import { isLocalEnvironment } from "./Dev/isDevelopment";
type PlantContextProps = string;
export const PlantContext = createContext<PlantContextProps>("");
const devPlant = process.env.REACT_APP_DEVELOPMENT_PLANT || "";

const returnPlantForDevelopmentBasedOnEnv = () => {
  if (isLocalEnvironment()) {
    localStorage.setItem(STORAGE_CURRENT_PLANT_KEY, devPlant);
    return devPlant;
  }

  return "";
};

type PlantWrapperProps = {
  children: ReactNode;
};
export const PlantWrapper = ({ children }: PlantWrapperProps) => {
  const plantName = localStorage.getItem(STORAGE_CURRENT_PLANT_KEY) || returnPlantForDevelopmentBasedOnEnv();
  return <PlantContext.Provider value={plantName}>{children}</PlantContext.Provider>;
};
