import { AppSidebar } from "@vimana/ui-components/dist/Components/App/AppSidebar";
import { EnvContext } from "@vimana/ui-components/dist/Components/Env/EnvContext";
import { EnvConsumerProps } from "@vimana/ui-components/dist/Components/Env/EnvContext/EnvContext";
import { PlantInfo } from "@vimana/ui-components/dist/Components/Plant/PlantInfo";
import { val } from "@vimana/ui-components/dist/Components/Theme";
import { ResetPasswordRoutes } from "@vimana/ui-components/dist/Components/User/Passwords";
import { isInIFrame } from "@vimana/ui-components/dist/Utils/isInIFrame";
import React, { useContext, useState } from "react";
import { match } from "react-router-dom";
import styled from "styled-components";

import { PlantContext } from "../../PlantWrapper";

const UserRoutes = React.lazy(() => import(/* webpackChunkName: "UserRoutes" */ "./UserRoutes"));
const TargetsRoutes = React.lazy(() => import(/* webpackChunkName: "TargetRoutes" */ "./TargetRoutes"));
const TicketRoutes = React.lazy(() => import(/* webpackChunkName: "TicketRoutes" */ "./TicketRoutes"));

const AlertRoutes = React.lazy(() => import(/* webpackChunkName: "AlertRoutes" */ "./AlertRoutes"));
const CalendarRoutes = React.lazy(() => import(/* webpackChunkName: "CalendarRoutes" */ "./CalendarRoutes"));
const DashboardRoutes = React.lazy(() => import(/* webpackChunkName: "DashboardRoutes" */ "./DashboardRoutes"));
const DataOnDemandRoutes = React.lazy(
  () => import(/* webpackChunkName: "DataOnDemandRoutes" */ "./DataOnDemandRoutes")
);
const DeviceRoutes = React.lazy(() => import(/* webpackChunkName: "DeviceRoutes" */ "./DeviceRoutes"));
const DownloadDeviceDataRoute = React.lazy(
  () => import(/* webpackChunkName: "DownloadDeviceDataRoute" */ "./DownloadDeviceDataRoute")
);
const LandingScreenRoute = React.lazy(
  () => import(/* webpackChunkName: "LandingScreenRoute" */ "./LandingScreenRoute")
);
const PlantStateActivitiesRoutes = React.lazy(
  () => import(/* webpackChunkName: "PlantStateActivitiesRoutes" */ "./PlantStateActivitiesRoutes")
);
const PlantTwinRoutes = React.lazy(() => import(/* webpackChunkName: "PlantTwinRoutes" */ "./PlantTwinRoutes"));
const ReportRoutes = React.lazy(() => import(/* webpackChunkName: "ReportRoutes" */ "./ReportRoutes"));
const PredefinedComments = React.lazy(
  () => import(/* webpackChunkName: "PredefinedCommentsRoutes" */ "./PredefinedCommentsRoutes")
);
const RuleConfigRoute = React.lazy(() => import(/* webpackChunkName: "RuleConfigRoute" */ "./RuleConfigRoute"));
const DeviceRuleConfigRoute = React.lazy(
  () => import(/* webpackChunkName: "RuleConfigRoute" */ "./DeviceRuleConfigRoute")
);
const SystemInsightsRoutes = React.lazy(
  () => import(/* webpackChunkName: "SystemInsightsRoutes" */ "./SystemInsightsRoutes")
);
const WorkInstructionRoutes = React.lazy(
  () => import(/* webpackChunkName: "WorkInstructionRoutes" */ "./WorkInstructionRoutes")
);
const AdminConfigurationRoutes = React.lazy(
  () => import(/* webpackChunkName: "WorkInstructionRoutes" */ "./AdminConfigurationRoutes")
);
const UserNotificationRoutes = React.lazy(
  () => import(/* webpackChunkName: "WorkInstructionRoutes" */ "./UserNotificationsRoutes")
);

const OperatorPanelRoutes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OperatorPanelRoutes" */ "@vimana/lib-ui-operator-panel/dist/src/Components/Routes/Routes"
    )
);
const MaintenanceManagerRoutes = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MaintenanceManagerRoutes" */ "@vimana/lib-ui-maintenance-manager/dist/src/Components/Routes/Routes"
    )
);

const AuditLogRoutes = React.lazy(() => import(/* webpackChunkName: "AuditLogRoutes" */ "./AuditLogRoutes"));

const HistoricalPartCountRoutes = React.lazy(
  () => import(/* webpackChunkName: "HistoricalPartCountRoutes" */ "./HistoricalPartCountRoutes")
);

const FlexContainer = styled.main`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
`;
const ScreenContainer = styled.div`
  display: ${(props) => (props.noSideBar ? "none" : "flex")};
  min-height: 100vh;
  margin-left: auto;
  transition: all ease-out ${val("SidebarTransitionDuration")};
  width: calc(
    100vw -
      ${isInIFrame()
        ? "0vw"
        : (props) => (!props.isSidebarExpanded ? val("SidebarWidth") : val("SidebarExpandedWidth"))}
  );
`;
type Props = {
  noSideBar: match<any> | null;
};

export const ApplicationRoutes = (props: Props) => {
  const { noSideBar } = props;
  const plantName = useContext(PlantContext);
  const env: EnvConsumerProps = useContext(EnvContext);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  return (
    <FlexContainer>
      {plantName === env?.administrativePlantName ? (
        <SystemInsightsRoutes />
      ) : (
        <PlantInfo.Provider>
          {noSideBar ? null : (
            <AppSidebar
              isSidebarExpanded={isSidebarExpanded}
              setIsSidebarExpanded={setIsSidebarExpanded}
              plantName={plantName}
            />
          )}
          <LandingScreenRoute />

          <ResetPasswordRoutes />

          <ScreenContainer noSideBar={noSideBar} isSidebarExpanded={isSidebarExpanded}>
            <DashboardRoutes />
            <ReportRoutes />
            <OperatorPanelRoutes />
            <TicketRoutes />
            <AlertRoutes />
            <PlantStateActivitiesRoutes />
            <UserRoutes />
            <CalendarRoutes />
            <DownloadDeviceDataRoute />
            <DeviceRoutes />
            <RuleConfigRoute />
            <TargetsRoutes />
            <WorkInstructionRoutes />
            <DataOnDemandRoutes />
            <PlantTwinRoutes />
            <MaintenanceManagerRoutes />
            <PredefinedComments />
            <DeviceRuleConfigRoute />
            <AuditLogRoutes />
            <HistoricalPartCountRoutes />
            <AdminConfigurationRoutes />
            <UserNotificationRoutes />
          </ScreenContainer>
        </PlantInfo.Provider>
      )}
    </FlexContainer>
  );
};
