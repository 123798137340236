import { ErrorBoundary } from "@vimana/ui-components/dist/Components/Core/ErrorBoundary";
import React, { createContext, ReactNode, useState } from "react";
type setError = (error: unknown) => void;
type ErrorContextProps =
  | {
      setError: setError;
    }
  | Record<string, any>;
export const ErrorContext = createContext<ErrorContextProps>({});
type ErrorHandlerProps = {
  children: ReactNode;
};
export const ErrorHandler = ({ children }: ErrorHandlerProps) => {
  return (
    <ErrorBoundary>
      <HookErrorHandler>{children}</HookErrorHandler>
    </ErrorBoundary>
  );
};

const HookErrorHandler = ({ children }) => {
  const [error, setError] = useState(null);

  if (error) {
    throw error;
  }

  return (
    <ErrorContext.Provider
      value={{
        setError: setError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};
