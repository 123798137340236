import { STORAGE_JWT_TOKEN_KEY, STORAGE_REFRESH_TOKEN_KEY } from "vi-auth-session";
const username = process.env.REACT_APP_AUTOMATIC_LOGIN_USERNAME;
const password = process.env.REACT_APP_AUTOMATIC_LOGIN_PASSWORD;
const plant = process.env.REACT_APP_AUTOMATIC_LOGIN_PLANT;
export const autoLogin = async (fetchToken: any) => {
  const { data } = await fetchToken({
    username,
    password,
    plant,
  });
  localStorage.setItem(STORAGE_JWT_TOKEN_KEY, data.access_token);
  localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, data.refresh_token);
};
