import "./App.css";

import React from "react";
import { HashRouter } from "react-router-dom";

import { PlantWrapper } from "./PlantWrapper";
import { ProtectedRoutes } from "./Routes/ProtectedRoutes";
import { UnProtectedRoutes } from "./Routes/UnProtectedRoutes";
import { ThemeWrapper } from "./ThemeWrapper";
import { LocaleProvider } from "./Translator/LocaleProvider";
import { ErrorHandler } from "./Utils/errorHandler";

/**
 * Service worker assumes ThemeWrapper for a plant loads before any other components.
 * This is to access plant information from branding:
 * - See src.ts
 */
export const App = () => {
  return (
    <ErrorHandler>
      <PlantWrapper>
        <ThemeWrapper>
          <LocaleProvider>
            <HashRouter>
              <ProtectedRoutes />
              <UnProtectedRoutes />
            </HashRouter>
          </LocaleProvider>
        </ThemeWrapper>
      </PlantWrapper>
    </ErrorHandler>
  );
};
