import { AuthContext } from "@vimana/ui-components/dist/Components/Auth/AuthContext";
import { useContext, useEffect, useState } from "react";
import { STORAGE_CURRENT_PLANT_KEY, STORAGE_JWT_TOKEN_KEY } from "vi-auth-session";

import { getApm } from "../apm";
import { isLocalEnvironment } from "../Dev/isDevelopment";
import { PlantContext } from "../PlantWrapper";
import { assetsFetchUrl } from "../Utils/fetchAPI";

type EnvProps = {
  apiURL: string;
  websocketURL: string;
  apmServerUrl: string;
  apmClientName: string;
  appVersion: string;
  featureFlags: string;
  enableApm: boolean;
};

type APMProps = {
  applicationProperties: Record<string, any> | EnvProps;
  children: (arg0: any) => JSX.Element;
};
export const STORAGE_APM_SERVER_URL = "vi-apm-server-url";

const messageHeadersToServiceWorkers = (apmServerUrl: string, assetsFetchUrl: string) => {
  const { serviceWorker } = navigator;

  if (serviceWorker && serviceWorker.controller) {
    const { controller } = serviceWorker;
    const jwt = localStorage.getItem(STORAGE_JWT_TOKEN_KEY) || "";
    const plant = localStorage.getItem(STORAGE_CURRENT_PLANT_KEY) || "";
    localStorage.setItem(STORAGE_APM_SERVER_URL, apmServerUrl);
    controller.postMessage({
      jwt,
      plant,
      apmServerUrl,
      assetsFetchUrl,
    });
  } else {
    setTimeout(() => messageHeadersToServiceWorkers(apmServerUrl, assetsFetchUrl), 1000);
  }
};

export const APMController = ({ children, applicationProperties }: APMProps) => {
  const [apm, setApm] = useState({});
  const { enableApm } = applicationProperties;
  const { username } = useContext(AuthContext);
  const plantName = useContext(PlantContext);
  console.warn("APP version: ", applicationProperties.appVersion);
  useEffect(() => {
    if (enableApm) {
      const pageName = window.location.hash.substring(1);
      const jwt = localStorage.getItem(STORAGE_JWT_TOKEN_KEY) || "";
      const plant = localStorage.getItem(STORAGE_CURRENT_PLANT_KEY) || "";
      const apmInstance = getApm({
        serviceName: applicationProperties.apmClientName || "s-ui-elevate",
        serverUrl: applicationProperties.apmServerUrl,
        serviceVersion: applicationProperties.appVersion,
        authorization: `Bearer ${jwt}`,
        tenant: `${plant}`,
      });

      const getAssetHost = () =>
        isLocalEnvironment() ? applicationProperties.apiURL : `https://${window.location.host}`;

      messageHeadersToServiceWorkers(applicationProperties.apmServerUrl, assetsFetchUrl(getAssetHost()));
      apmInstance.apm.setInitialPageLoadName(pageName);
      apmInstance.apm.setUserContext({
        username,
      });
      apmInstance.apm.addLabels({
        plant: plantName,
      });
      setApm(apmInstance);
    }
  }, [applicationProperties, username, plantName]);
  return children(apm);
};
