import { AuthContext } from "@vimana/ui-components/dist/Components/Auth/AuthContext";
import { Spinner } from "@vimana/ui-components/dist/Components/Core/Spinner";
import React, { ReactNode, useContext, useEffect, useState } from "react";

import { FETCH_LICENSING_FOR_PLANT_URI_PREFIX } from "../constants";
import { PlantContext } from "../PlantWrapper";
import { LocaleContext } from "../Translator/LocaleProvider";
import { awaitWrapper } from "../Utils/awaitWrapper";
import { createEnhancedContext } from "../Utils/createEnhancedContext";
import { createHandlers } from "../Utils/createHandlers";
import { ErrorContext } from "../Utils/errorHandler";
import { fetchAPI, getHost, wrapHttps, wrapWss } from "../Utils/fetchAPI";

export const getLicensingProps = async (apiHostName: string, headers: any, plantName: string) => {
  return await fetchAPI(`https://${apiHostName}`, `${FETCH_LICENSING_FOR_PLANT_URI_PREFIX}/${plantName}`, headers);
};

const fetchApplicationProps = async (plantName, { headers }, handlers) => {
  const { onAPIRequestStart, onSuccess, onAPIRequestFinish } = handlers;
  onAPIRequestStart();
  const {
    apiHostName,
    wssHostName,
    apmServerUrl,
    apmClientName,
    appVersion,
    featureFlags,
    gMapsApiKey,
    defaultDocumentationUrl,
    defaultCustomerSupportUrl,
    deviceConnectionTimeout,
    deviceLagThreshold,
    warningClassificationTime,
    imageInputMaxSizeInKB,
    administrativePlantName,
    notificationsInterval,
    enableApm,
  } = await fetchAPI(getHost(), "/env.json");
  const licence: any = await getLicensingProps(apiHostName, headers, plantName);
  onSuccess({
    apiURL: wrapHttps(apiHostName),
    websocketURL: wrapWss(wssHostName),
    licence,
    apmServerUrl,
    apmClientName,
    appVersion,
    featureFlags,
    googleMapAPIKey: gMapsApiKey,
    defaultDocumentationUrl,
    defaultCustomerSupportUrl,
    deviceConnectionTimeout,
    deviceLagThreshold,
    warningClassificationTime,
    imageInputMaxSizeInKB,
    administrativePlantName: administrativePlantName ?? "systeminsights",
    notificationsInterval: notificationsInterval ?? 60000,
    enableApm,
  });
  onAPIRequestFinish();
};

export type EnvProps =
  | {
      apiURL: string;
      websocketURL: string;
      apmServerUrl: string;
      apmClientName: string;
      appVersion: string;
      featureFlags: string;
      defaultDocumentationUrl: string;
      defaultCustomerSupportUrl: string;
      deviceConnectionTimeout: number;
      deviceLagThreshold: number;
      warningClassificationTime: number;
      imageInputMaxSizeInKB: number;
      administrativePlantName: string;
      notificationsInterval: number;
      enableApm: boolean;
    }
  | Record<string, any>;

type UseDomainProps = {
  loading: boolean;
  applicationProperties: EnvProps;
};

export const useDomainProps = (): UseDomainProps => {
  const auth: any = useContext(AuthContext);
  const plantName = useContext(PlantContext);
  const [loading, setLoading] = useState(true);
  const [applicationProperties, setApplicationProperties] = useState({});
  const { setError } = useContext(ErrorContext);
  useEffect(() => {
    const headers = {
      "X-Tenant": plantName,
      Authorization: `Bearer ${auth.token}`,
    };

    awaitWrapper(
      fetchApplicationProps(
        plantName,
        {
          headers,
        },
        createHandlers(setLoading, setApplicationProperties)
      ),
      setError
    );
  }, [auth, setError, plantName]);
  return {
    loading,
    applicationProperties,
  };
};
export type IApplicationPropertiesConsumerProps = EnvProps & {
  locale: string;
  changeLocale: (arg0: string) => void;
};

type IApplicationProperties = {
  children: (arg0: IApplicationPropertiesConsumerProps) => ReactNode;
};
export const ApplicationPropertiesController = ({ children }: IApplicationProperties) => {
  const { loading, applicationProperties } = useDomainProps();
  const { locale, changeLocale } = useContext(LocaleContext);
  if (loading) return <Spinner />;

  return children({
    ...applicationProperties,
    locale,
    changeLocale,
  });
};
export const ApplicationPropertiesContext = createEnhancedContext({
  component: ApplicationPropertiesController,
});
