import german from "@vimana/ui-components/translations/de-de.json";
import english from "@vimana/ui-components/translations/en-us.json";
import spanish from "@vimana/ui-components/translations/es-es.json";
import finnish from "@vimana/ui-components/translations/fi-FI.json";
import french from "@vimana/ui-components/translations/fr-fr.json";
import hungarian from "@vimana/ui-components/translations/hu-hu.json";
import italian from "@vimana/ui-components/translations/it-it.json";
import japanese from "@vimana/ui-components/translations/ja-jp.json";
import korean from "@vimana/ui-components/translations/ko-kr.json";
import polish from "@vimana/ui-components/translations/pl-pl.json";
import brazilian from "@vimana/ui-components/translations/pt-BR.json";
import chinese from "@vimana/ui-components/translations/zh-cn.json";

export const getLocaleMessages = (locale: string): any => {
  switch (locale) {
    case "de-de":
      return german;

    case "pt-BR":
      return brazilian;

    case "en-us":
      return english;

    case "es-es":
      return spanish;

    case "fr-fr":
      return french;

    case "fi-FI":
      return finnish;

    case "it-it":
      return italian;

    case "ja-jp":
      return japanese;

    case "ko-kr":
      return korean;

    case "pl-pl":
      return polish;

    case "hu-hu":
      return hungarian;

    case "zh-cn":
      return chinese;

    default:
      return english;
  }
};
