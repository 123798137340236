import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function usePageView() {
  const location = useLocation();
  const [referrer, setReferrer] = useState("");
  useEffect(() => {
    const path = location.pathname + location.search + location.hash;
    window["gtag"]("event", "page_view", {
      page_path: path,
      page_search: location.search,
      page_hash: location.hash,
      page_location: window.location.href,
      page_referrer: referrer,
    });
    setReferrer(window.location.href);
  }, [location]);
}

export default usePageView;
