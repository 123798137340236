import { Spinner } from "@vimana/ui-components/dist/Components/Core/Spinner";
import { UhOh } from "@vimana/ui-components/dist/Components/Core/UhOh";
import { EnvContext as UIEnvContext } from "@vimana/ui-components/dist/Components/Env/EnvContext";
import { ForgotPasswordScreen } from "@vimana/ui-components/dist/Components/User/Passwords";
import React, { useContext, useEffect, useState } from "react";
import { STORAGE_JWT_TOKEN_KEY, STORAGE_REFRESH_TOKEN_KEY } from "vi-auth-session";

import { PlantContext } from "../../PlantWrapper";
import { ErrorContext } from "../../Utils/errorHandler";
import { fetchAPI, getHost } from "../../Utils/fetchAPI";
export const ForgotPasswordRoute = () => {
  const [loading, setLoading] = useState(true);
  const [envProps, setEnvProps] = useState<any>({});
  const plantName = useContext(PlantContext);
  const error = useContext(ErrorContext);
  useEffect(() => {
    localStorage.removeItem(STORAGE_JWT_TOKEN_KEY);
    localStorage.removeItem(STORAGE_REFRESH_TOKEN_KEY);

    if (!plantName) {
      return;
    }

    fetchAPI(getHost(), "/env.json")
      .then((env) => {
        setLoading(false);
        setEnvProps(env);
      })
      .catch((err) => {
        setLoading(false);
        error.setError(err);
      });
  }, [error, plantName]);

  if (!plantName) {
    return (
      <UIEnvContext.Provider
        value={{
          apiURL: `https://${envProps.apiHostName}`,
          apmProps: {},
          clientId: "",
          clientSecret: "",
          plant: "",
        }}
      >
        <UhOh error={new Error("Plant Not Found")} />
      </UIEnvContext.Provider>
    );
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <UIEnvContext.Provider
        value={{
          apiURL: `https://${envProps.apiHostName}`,
          apmProps: {},
          clientId: "",
          clientSecret: "",
          plant: plantName,
        }}
      >
        <ForgotPasswordScreen />
      </UIEnvContext.Provider>
    </>
  );
};
