import { APIProvider } from "@vimana/ui-components/dist/Components/API";
import { NavbarSettingsMenuController } from "@vimana/ui-components/dist/Components/App/AppNavbar";
import { AIChatContext } from "@vimana/ui-components/dist/Components/Chat/AI/AIChatContext";
import { ErrorBoundary } from "@vimana/ui-components/dist/Components/Core/ErrorBoundary";
import { ElevateSettingsMenu } from "@vimana/ui-components/dist/Components/LandingScreen/ElevateSettingsMenu";
import { NotificationsIconContext } from "@vimana/ui-components/dist/Components/LandingScreen/NotificationsIcon/NotificationsIconContext";
import { ProfileContext } from "@vimana/ui-components/dist/Components/Profile/ProfileContext";
import { ProfilePermissionsContext } from "@vimana/ui-components/dist/Components/Profile/ProfilePermissionsContext";
import { Suspense, useContext } from "react";
import { useRouteMatch } from "react-router-dom";

import { ApplicationPropertiesContext } from "../ApplicationContext/ApplicationPropertiesController";
import { AuthController } from "../ApplicationContext/AuthController";
import { EnvContext } from "../ApplicationContext/EnvContext";
import { PlantContext } from "../PlantWrapper";
import usePageView from "../Utils/usePageView";
import { ApplicationRoutes } from "./ProtectedRoutes/ApplicationRoutes";

export const ProtectedRoutes = () => {
  const plantName = useContext(PlantContext);
  usePageView();
  const isForgotPassword = useRouteMatch({
    path: "/forgot-password",
    exact: true,
  });
  const isLandingRoute = useRouteMatch({
    path: "/",
    exact: true,
  });
  const isResetPassword = useRouteMatch({
    path: "/reset-password",
    exact: true,
  });
  const noSideBar = isLandingRoute || isResetPassword;

  if (isForgotPassword) {
    return null;
  }

  return (
    <AuthController>
      <ApplicationPropertiesContext.Provider value={{}}>
        <EnvContext>
          <APIProvider plantName={plantName}>
            <ProfileContext.Provider>
              <ProfilePermissionsContext.Provider>
                <NotificationsIconContext.Provider>
                  <AIChatContext.Provider>
                    <NavbarSettingsMenuController settingsMenu={ElevateSettingsMenu}>
                      <ErrorBoundary>
                        <Suspense fallback={null}>
                          <ApplicationRoutes noSideBar={noSideBar} />
                        </Suspense>
                      </ErrorBoundary>
                    </NavbarSettingsMenuController>
                  </AIChatContext.Provider>
                </NotificationsIconContext.Provider>
              </ProfilePermissionsContext.Provider>
            </ProfileContext.Provider>
          </APIProvider>
        </EnvContext>
      </ApplicationPropertiesContext.Provider>
    </AuthController>
  );
};
