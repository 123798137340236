import { init as initApm } from "@elastic/apm-rum";
import { ApmRoute } from "@elastic/apm-rum-react";

type ApmConfig = {
  serviceName: string;
  serverUrl: string;
  serviceVersion: string;
  authorization: string;
  tenant: string;
};

export const getApm = ({ serviceName, serverUrl, serviceVersion, authorization, tenant }: ApmConfig) => {
  const apm = initApm({
    serviceName,
    serverUrl,
    serviceVersion,
    // @ts-expect-error: Unreachable code error
    authorization,
    tenant,
  });

  return {
    apm,
    route: ApmRoute,
  };
};
