import { ELEVATE_REALTIME_URI_SUFFIX, FETCH_MEDIA_ASSESTS_URI_PREFIX } from "../constants";
export const getHost = () => {
  const envHost = process.env.REACT_APP_DEV_HOST;

  if (typeof window !== "undefined" && window.location.host.includes("localhost") && envHost) {
    return `https://${envHost}`;
  }

  return "";
};
export const fetchAPI = async (host: string, url: string, headers?: any) => {
  const uri = `${host}${url}`;
  const response = await fetch(uri, {
    headers,
  });

  if (response.status === 200) {
    return response.json();
  }

  throw Error(`${url} not found`);
};

export const wrapWss = (url: string) => (url.includes("wss") ? url : `wss://${url}${ELEVATE_REALTIME_URI_SUFFIX}`);
export const wrapHttps = (url: string) => (url.includes("https") ? url : `https://${url}`);
export const assetsFetchUrl = (url: string) => {
  return `${wrapHttps(url)}${FETCH_MEDIA_ASSESTS_URI_PREFIX}`;
};
