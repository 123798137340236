import "@vimana/ui-components/dist/Themes/base/base-theme.css";

import { Spinner } from "@vimana/ui-components/dist/Components/Core/Spinner";
import { BlueprintStyleOverrides, ThemeProvider } from "@vimana/ui-components/dist/Components/Theme";
import { getThemeByName, getThemeWrapperByName } from "@vimana/ui-components/dist/Themes";
import React, { ReactNode, useContext, useEffect, useState } from "react";

import { PlantContext } from "./PlantWrapper";
import { awaitWrapper } from "./Utils/awaitWrapper";
import { createHandlers } from "./Utils/createHandlers";
import { ErrorContext } from "./Utils/errorHandler";
import { fetchAPI, getHost } from "./Utils/fetchAPI";

const fetchBranding = async (plantName, _, handlers) => {
  const { onAPIRequestStart, onSuccess, onAPIRequestFinish } = handlers;
  onAPIRequestStart();
  const { themeName } = await fetchAPI(getHost(), `/api/v3/licensing/branding/${plantName}`);
  onSuccess(themeName);
  onAPIRequestFinish();
};

const useThemeProps = () => {
  const plantName = useContext(PlantContext);
  const { setError } = useContext(ErrorContext);
  const [loading, setLoading] = useState(true);
  const [themeName, setThemeName] = useState({});
  useEffect(() => {
    if (!plantName) {
      return;
    }

    awaitWrapper(fetchBranding(plantName, {}, createHandlers(setLoading, setThemeName)), setError);
  }, [plantName, setError]);

  if (!plantName) {
    return {
      loading: false,
      themeName: "base",
    };
  }

  return {
    loading,
    themeName,
  };
};

const addBodyClasses = (theme) => {
  if (document.body) {
    document.body.className = "";
    document.body.classList.add(...theme.bodyClassName.split(" "));
  }
};

type ThemeWrapperProps = {
  children: ReactNode;
};
export const ThemeWrapper = ({ children }: ThemeWrapperProps) => {
  const { loading, themeName } = useThemeProps();
  const ThemeWrapper = getThemeWrapperByName(themeName);
  const theme = getThemeByName(themeName);
  useEffect(() => {
    addBodyClasses(theme);
  }, [theme]);

  if (loading) {
    return <Spinner />;
  }

  const appContent = (
    <ThemeProvider theme={theme}>
      <ThemeWrapper>
        <BlueprintStyleOverrides />
        <div
          style={{
            background: theme.ScreenBackground,
          }}
        >
          {children}
        </div>
      </ThemeWrapper>
    </ThemeProvider>
  );
  return appContent;
};
